import React from 'react'
import { Button, type Theme, Typography, styled } from '@mui/material'
import MuiAlert, { type AlertProps } from '@mui/material/Alert'
import { MbscPopupDisplay } from '@mobiscroll/react'

export const ScheduleStats = styled('div')(({ theme }): any => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  height: '44px',
  background: theme.palette.background.default,
  borderBottom: '1px solid #E6E7EF',
  boxShadow: '0px 4px 4px rgba(64, 71, 118, 0.05)',
  marginLeft: '-12px',
  marginRight: '-12px',
  padding: '0px 12px',
  justifyContent: 'space-between',
}))

export const ScheduleStatTile = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
})

export const ScheduleStatValueTile = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '4px 8px',
  gap: '2px',
  height: '24px',
  background: '#F6F8FD',
  borderRadius: '6px',
})

export const ScheduleStatTitle = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.01em',
})

export const ScheduleStatValue = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  color: 'black',
})

export const FilterRow = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
})

export const FilterDropDowns = styled('div')({
  display: 'flex',
  gap: 10,
})

export const SchedulingContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export const SchedulingContent = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 1,
})

interface SettingsContentProps {
  isNavOpen: boolean;
  theme: Theme;
  isMainView: boolean;
}

export const SchedulingContentRightPane = styled('div')(
  ({ isNavOpen, theme, isMainView }: SettingsContentProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: `${
      isMainView ? (isNavOpen ? '420px' : '350px') : isNavOpen ? '70px' : '0px'
    }`,
    padding: '68px 12px 12px 20px',
    backgroundColor: theme.palette.background.default,
  })
)

export const StyledButton = styled(Button)(
  ({ selected }: { selected: boolean }) => ({
    backgroundColor: selected ? '#ffffff' : '#e0e0e0', // white when selected, grey when not
    color: selected ? 'black' : 'rgba(0, 0, 0, 0.87)',
    textTransform: 'none',
    fontSize: '0.875rem',
    padding: '8px',
    fontWeight: 400,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: selected ? '#ffffff' : '#d5d5d5', // remain white when selected, lighter grey when not
    },
    boxShadow: 'none',
    border: '1px solid #ccc', // assuming a light grey border
    borderRadius: '4px', // set the border-radius to 4px
    '&:not(:last-child)': {
      // Prevent double borders between buttons
      // Set the radius on the right corners of the first button and the left corners of the last button to 0
      '&:first-of-type': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      },
      '&:last-of-type': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
      },
    },
  })
)

export const ViewComponent = styled('div')({
  marginLeft: 'auto',
})

export const responsivePopup = {
  medium: {
    display: 'anchored' as MbscPopupDisplay,
    width: 520,
    fullScreen: false,
    touchUi: false,
  },
}

export const selectResponsive = {
  xsmall: {
    touchUi: true,
  },
  small: {
    touchUi: false,
  },
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

export const days = [
  {
    name: 'Sunday',
    short: 'SU',
    checked: true,
  },
  {
    name: 'Monday',
    short: 'MO',
    checked: false,
  },
  {
    name: 'Tuesday',
    short: 'TU',
    checked: false,
  },
  {
    name: 'Wednesday',
    short: 'WE',
    checked: false,
  },
  {
    name: 'Thursday',
    short: 'TH',
    checked: false,
  },
  {
    name: 'Friday',
    short: 'FR',
    checked: false,
  },
  {
    name: 'Saturday',
    short: 'SA',
    checked: false,
  },
]

export const months = [
  {
    value: 1,
    text: 'January',
  },
  {
    value: 2,
    text: 'February',
  },
  {
    value: 3,
    text: 'March',
  },
  {
    value: 4,
    text: 'April',
  },
  {
    value: 5,
    text: 'May',
  },
  {
    value: 6,
    text: 'June',
  },
  {
    value: 7,
    text: 'July',
  },
  {
    value: 8,
    text: 'August',
  },
  {
    value: 9,
    text: 'September',
  },
  {
    value: 10,
    text: 'October',
  },
  {
    value: 11,
    text: 'November',
  },
  {
    value: 12,
    text: 'December',
  },
]

export const ordinalList = { 1: 'first', 2: 'second', 3: 'third', 4: 'fourth' }

export const dayInputProps = {
  className: 'custom-repeat-input custom-repeat-select-nr',
  inputStyle: 'outline',
}

export const monthInputProps = {
  className: 'custom-repeat-input custom-repeat-select-month',
  inputStyle: 'outline',
}

export const dateInputProps = {
  className: 'custom-repeat-input custom-specific-date',
  inputStyle: 'outline',
}

export const ErrorMessage = {
  ApptNotExisting:
    "Sorry, this appointment is not existing in our database. We'll fix this appointment.",
  ErrorInDeletion: 'Sorry, we have an issue in deleting this appointment.',
  ErrorInUpdating: 'Sorry, we have an issue in updating this appointment.',
  PatientNotExisting: 'Sorry, we have a problem with this patient.',
  PatientNotSelected: 'Please select a patient.',
}

// returns the weeknumber of the passed date
export function getWeekDayNum(date: Date): number {
  const year = date.getFullYear()
  const month = date.getMonth()
  const firstDayOfMonth = new Date(year, month, 1)
  const lastDayOfMonth = new Date(year, month + 1, 0)
  let count = 0

  // eslint-disable-next-line no-unmodified-loop-condition
  for (
    // eslint-disable-next-line no-unmodified-loop-condition
    let d = firstDayOfMonth;
    // eslint-disable-next-line no-unmodified-loop-condition
    d < lastDayOfMonth;
    // eslint-disable-next-line no-unmodified-loop-condition
    d.setDate(d.getDate() + 7)
  ) {
    if (date >= d) {
      count++
    } else {
      break
    }
  }

  return Math.max(1, count)
}

export const safelyParseJSON = (str: string): any => {
  try {
    return JSON.parse(str)
  } catch (e) {
    console.error('Error parsing JSON:', e)
    return undefined
  }
}
